import { Routes } from '@angular/router';
import { TrackerGamblerBindingProfileComponent } from './components/tracker-gambler-profile/tracker-gambler-binding-profile.component';

export const TrackerRoutes: Routes = [
    {
        path: '',
        children: [
            {
                path: 'gamblerBindingProfile',
                component: TrackerGamblerBindingProfileComponent,
            },
        ],
    },
];
