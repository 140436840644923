<div class="row">
    <div class="col-12">
        <app-wl-dropdown
                [options]="{placeholder: dropDownPlaceHolder, title: title, clearAfterSelect: clearAfterSelect }"
                [control]="control"
                [strategy]="strategy"
                [shouldShowErrorList]="false">
        </app-wl-dropdown>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <mat-chip-listbox>
            <mat-chip-option *ngFor="let selected of selectedElements"
                             [selectable]="true"
                             [removable]="true"
                             (removed)="removeElement(selected)">
                {{ getElementName(selected) }}
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip-option>
        </mat-chip-listbox>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <div class="errors-list" *ngIf="(this.control.shouldDisplayErrors | async) && this.shouldShowErrorList">
            <span class="error-item" *ngFor="let error of this.control.strictErrors | async">
                {{error.message}}
            </span>
        </div>
    </div>
</div>

