<mat-sidenav-container class="wl-main-body-container">
    <mat-sidenav #toolbarDrawer class="wl-left-menu" [mode]="isMobile ? 'push' : 'side'" [opened]="sidebarOpenedByDefault" [fixedInViewport]="false">
        <mat-nav-list class="wl-left-menu-nav-list">
            <mat-expansion-panel class="wl-menu-sidenav" *ngFor="let item of menu; index as i">
                <mat-expansion-panel-header class="wl-menu-accardion-item">
                    <svg
                            width="20"
                            height="18"
                            viewBox="0 0 20 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                [attr.d]="item.image"
                                fill="#000000de"
                        />
                    </svg>
                    <span class="wl-span-header-text">{{ item.nameMenu }}</span>
                </mat-expansion-panel-header>
                <a
                        mat-list-item
                        class="wl-sidenav-item"
                        [class.wl-sidenav-item-active]="true"
                        *ngFor="let navItem of item.sideNav"
                        (click)="handleMenuItemClick()"
                        [routerLink]="[navItem.url]"
                >
                    {{ navItem.name }}
                </a>
            </mat-expansion-panel>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content class="wl-app-workspace-container">
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>
