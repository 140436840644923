import {Observable} from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import {Injectable} from '@angular/core';
import { BattlePassService, WdsBetMarket, WdsMarketFilterForm } from 'src/app/modules/autogen/BattlePass';
import { IScrollService } from 'src/app/common/abstracts/types/scroll-service-interface';
import { WlResult } from 'src/app/models/common/results/wl-result';
import { GaMessagingService } from '@koddington/ga-common';

@Injectable({
    providedIn: 'root'
})
export class WlBattlePassMarketsScrollServiceAdapter implements IScrollService<WdsBetMarket, WdsMarketFilterForm> {

    constructor(private readonly _service: BattlePassService,
                private readonly _messaging: GaMessagingService) {
    }

    public search(item: WdsMarketFilterForm): Observable<WlResult<WdsBetMarket[]>> {
        return this._service.listMarkets(item)
            .pipe(
                take(1),
                filter((value) => !this._messaging.tryShowError(value)),
                map(u => {
                    const res = new WlResult<WdsBetMarket[]>();
                    res.result = u.result.results;
                    return res;
                })
            );
    }
}
