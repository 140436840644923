import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { TrackerRoutes } from './app-tracker.module.routes';
import { GaCommonModule } from '@koddington/ga-common';
import { TrackerGamblerBindingProfileComponent } from './components/tracker-gambler-profile/tracker-gambler-binding-profile.component';
import { NgForOf, NgIf } from '@angular/common';
import { DailyTasksAreaCodegenAppModule } from '../autogen/DailyTasks';

@NgModule({
    imports: [
        SharedModule,
        RouterModule.forChild(TrackerRoutes),
        GaCommonModule,
        NgForOf,
        NgIf,
        DailyTasksAreaCodegenAppModule,
    ],
    declarations: [
        TrackerGamblerBindingProfileComponent
    ],
    exports: [
        TrackerGamblerBindingProfileComponent
    ],
    providers: [],
})
export class TrackerModule {
}
