import { Observable, of } from 'rxjs';
import { WlCrmBetPlatforms, WlCrmBetPlatformsExtensions } from '../../autogen/BattlePass';
import { DropdownItem } from '../../../models/common/enum-dropdown/dropdown-item';
import { IDropdownStrategy } from '@koddington/ga-common';

export class WlCrmBetPlatformsStrategy implements IDropdownStrategy<WlCrmBetPlatforms> {
    private collection = [
        WlCrmBetPlatforms.WebRu,
        WlCrmBetPlatforms.CheckinPPS,
        WlCrmBetPlatforms.IOS,
        WlCrmBetPlatforms.Android,
        WlCrmBetPlatforms.MobileRU,
        WlCrmBetPlatforms.NewWebRu,
    ];

    constructor() {
    }

    public getEntities(): Observable<Array<WlCrmBetPlatforms>> {
        return of(this.collection);
    }

    public map(model: WlCrmBetPlatforms): DropdownItem<WlCrmBetPlatforms> {
        return {
            entity: model,
            title: WlCrmBetPlatformsExtensions.format(model),
            id: model.toString()
        };
    }
}
