import { WlLevelViewModel } from '../../../models/wl-level-view-model';
import { WlBattlePassLevelCrmTaskValidator } from './wl-battle-pass-level-crm-task-validator';
import { WlBattlePassLevelGameTaskValidator } from './wl-battle-pass-level-game-task-validator';
import dayjs, {Dayjs} from 'dayjs';
import { WlBattlePassGameLootBoxVariation } from './helpers/wl-battle-pass-game-loot-box-variation';
import { WlBattlePassLevelGameLootBoxValidator } from './wl-battle-pass-level-game-loot-box-validator';
import { WlBattlePassLootBoxesConsts } from './helpers/wl-battle-pass-loot-boxes-consts';
import { WlBattlePassLevelFreeBetLootBoxValidator } from './wl-battle-pass-level-free-bet-loot-box.validator';
import { WlCompleteTaskTypes, WlFreeBetLootBoxListModel, WlGameLootBoxLiteModel } from 'src/app/modules/autogen/BattlePass';
import { AbstractModelValidator, isNullOrUndefined, StrictError, StrictFormControl } from '@koddington/ga-common';
import { WlCompleteTaskTypesExtensions } from '../../../../autogen/Shared';

export class WlBattlePassLevelValidator extends AbstractModelValidator<WlLevelViewModel> {
    private readonly validateCRMBP: WlBattlePassLevelCrmTaskValidator = new WlBattlePassLevelCrmTaskValidator();
    private readonly validateGameTaskBP: WlBattlePassLevelGameTaskValidator = new WlBattlePassLevelGameTaskValidator();
    private readonly gameLootBoxValidator = new WlBattlePassLevelGameLootBoxValidator();
    private readonly freeBetLootBoxValidator = new WlBattlePassLevelFreeBetLootBoxValidator();

    private startSeasonDate: Dayjs;
    private endSeasonDate: Dayjs;

    private _allGameLootBoxVariations: WlBattlePassGameLootBoxVariation[] = [];
    private _groupedGameLootBoxes = new Map<string, StrictFormControl<WlGameLootBoxLiteModel>[]>();
    private _groupedFreeBetLootBoxes = new Map<WlCompleteTaskTypes, StrictFormControl<WlFreeBetLootBoxListModel>[]>();

    constructor() {
        super();

        this.ruleForControl((u) => u.order)
            .required('Порядок уровня обязателен')
            .min(1)
            .max(60);

        this.ruleForControl((u) => u.name)
            .notEmptyString('Название уровня должно быть заполнено')
            .maxLength(200);

        this.ruleForControl((u) => u.description)
            .notEmptyString('Описание должно быть заполнено')
            .maxLength(200);

        this.ruleForControl((u) => u.imageUrl)
            .notEmptyString('Отсутствует ссылка на изображение')
            .maxLength(1500);

        this.ruleForControl((u) => u.iconUrl)
            .maxLength(1500);

        this.ruleForControl((u) => u.inactiveIconUrl)
            .maxLength(1500);

        this.setRulesForLootBoxes();

        this.ruleForControlIf((u) => u.template, u => !u.isGamblerLevel.strictValue)
            .required('Шаблон рассылки обязателен для стандартных уровней');

        this.ruleForControl((u) => u.gameTasks).customRule((arrayGame, model) => {
            let errors: StrictError[];
            errors = this.validateGameTaskBP.validateCustom(arrayGame, model.crmTaskGroups.strictValue, model.startDate.strictValue);
            if (errors.length > 0)
                return new StrictError('gameTasksHasErrors', '');

            return null;
        });

        this.ruleForControl((v) => v.crmTaskGroups).customRule((arrayCRM, model) => {
            const errors = this.validateCRMBP.validateCustom(model.gameTasks.strictValue, arrayCRM, model.startDate.strictValue);
            if (errors.length > 0)
                return errors[0];

            return null;
        });

        this.ruleForControl((u) => u.startDate).customRule((fieldValue, model) => {
            if (isNullOrUndefined(fieldValue)) {
                if (model.crmTaskGroups.strictValue.some(u => u.task.hasStrictValue || u.order.hasStrictValue))
                    return new StrictError('startDateTimeError', 'Необходимо выбрать дату начала уровня для уровня с заданиями');

                if (model.gameTasks.strictValue.some(u => u.task.hasStrictValue || u.order.hasStrictValue))
                    return new StrictError('startDateTimeError', 'Необходимо выбрать дату начала уровня для уровня с заданиями');
            }

            if (isNullOrUndefined(fieldValue))
                return null;

            if (isNullOrUndefined(this.startSeasonDate))
                return new StrictError('startDateTimeError', 'Дата начала уровня не может быть выбрана, пока не выбрана дата начала сезона');

            if (isNullOrUndefined(this.endSeasonDate))
                return new StrictError('startDateTimeError', 'Дата начала уровня не может быть выбрана, пока не выбрана дата технического расчёта');

            const dayjsVal = dayjs.isDayjs(fieldValue)
                ? fieldValue
                : dayjs(fieldValue);

            if (this.startSeasonDate.unix() > dayjsVal.unix())
                return new StrictError('startDateTimeError', 'Дата начала уровня не может быть не в пределах активности сезона');

            if (this.endSeasonDate.unix() < dayjsVal.unix())
                return new StrictError('startDateTimeError', 'Дата начала уровня не может быть не в пределах активности сезона');

            if (model.overallTasksCount(true) === 0)
                return new StrictError('startDateTimeError', 'Для уровня с датой старта должны быть указаны задания');

            return null;
        });
    }

    public validateCustom(startSeasonDate: Dayjs | null | undefined, endSeasonDate: Dayjs | null | undefined, model: WlLevelViewModel | null | undefined): StrictError[] {
        this.startSeasonDate = startSeasonDate;
        this.endSeasonDate = endSeasonDate;

        const gameTypes = WlBattlePassLootBoxesConsts.availableGameLootBoxTypes;
        const taskTypes = WlBattlePassLootBoxesConsts.availableCompleteTaskTypes;

        this._allGameLootBoxVariations = [].concat(
            ...gameTypes.map(u => taskTypes.map(v => new WlBattlePassGameLootBoxVariation(u, v)))
        );

        this._groupedGameLootBoxes.clear();
        model.gameLootBoxes.strictValue.forEach((value) => {
            const key = new WlBattlePassGameLootBoxVariation(value.lootBox.strictValue?.gameType, value.completedTaskType.strictValue).getDescription();
            if (!this._groupedGameLootBoxes.has(key)) {
                this._groupedGameLootBoxes.set(key, [value.lootBox]);
                return;
            }

            this._groupedGameLootBoxes.get(key).push(value.lootBox);
        });

        this._groupedFreeBetLootBoxes.clear();
        model.freeBetLootBoxes.strictValue.forEach((value) => {
            const key = value.completedTaskType.strictValue;
            if (!this._groupedFreeBetLootBoxes.has(key)) {
                this._groupedFreeBetLootBoxes.set(key, [value.lootBox]);
                return;
            }

            this._groupedFreeBetLootBoxes.get(key).push(value.lootBox);
        });

        return [
            ...super.validate(model),
            ...this.gameLootBoxValidator.validateCustom(model.gameLootBoxes.strictValue, this._groupedGameLootBoxes),
            ...this.freeBetLootBoxValidator.validateCustom(model.freeBetLootBoxes.strictValue, this._groupedFreeBetLootBoxes)
        ];
    }


    private setRulesForLootBoxes(): void {
        this.ruleForControl(u => u.gameLootBoxes)
            .required('Для уровня должны быть указаны игровые лутбоксы')
            .customRule((field, model) => {
                const missingCombinations = this._allGameLootBoxVariations
                    .filter(u => !field.some(v => v.completedTaskType.strictValue === u.taskType && v.lootBox.strictValue?.gameType === u.gameType));

                return missingCombinations.length === 0
                    ? null
                    : new StrictError(
                        'gameLootBoxesVariationsError',
                        `Отсутствуют варианты игровых лутбоксов: ${missingCombinations.map(u => u.getDescription()).join(', ')}`
                    );
            });

        this.ruleForControl(u => u.freeBetLootBoxes)
            .required('Для уровня должны быть указаны фрибетные лутбоксы')
            .customRule((field, model) => {
                const missingCombinations = WlBattlePassLootBoxesConsts.availableCompleteTaskTypes
                                                                       .filter(u => !field.some(v => v.completedTaskType.strictValue === u));

                return missingCombinations.length === 0
                    ? null
                    : new StrictError(
                        'gameLootBoxesVariationsError',
                        `Отсутствуют варианты фрибетных лутбоксов: ${missingCombinations.map(u => WlCompleteTaskTypesExtensions.format(u)).join(', ')}`
                    );
            });
    }
}
