import { AfterContentInit, AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { WlAuthStateService } from '../authorize/services/auth-state-service';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { MatDrawer } from '@angular/material/sidenav';
import { IWithToolBarComponent } from '../../../../common/abstracts/i-with-tool-bar-component';
import { isNullOrUndefined } from '@koddington/ga-common';

@Component({
    selector: 'app-root-component',
    templateUrl: './root.component.html',
    styleUrls: ['./root.component.scss'],
})
export class RootComponent implements OnInit, AfterViewInit, OnDestroy {
    protected hasToolbar = false;
    private routerOutletComponent: Component;
    private currentLogin: string = '';
    private toolbarDrawerFunc: () => void  = () => (this.routerOutletComponent as IWithToolBarComponent).toolbarDrawer.toggle();

    constructor(private readonly _userAuthStateService: WlAuthStateService) {
    }

    ngOnInit(): void {
        this._userAuthStateService.userState
            .pipe(untilDestroyed(this))
            .subscribe((u) => {
                if (!u?.login) {
                    this.currentLogin = 'Гость';
                    return;
                }
                this.currentLogin = u.login;
            });
    }

    public ngAfterViewInit(): void {
    }

    ngOnDestroy(): void {
    }

    get login(): string {
        return this.currentLogin;
    }

    get isLogin(): boolean {
        if (this.currentLogin === '' || this.currentLogin === 'Гость') {
            return false;
        }
        return true;
    }

    public toggleToolbar(): void {
        if (this.hasToolbar)
            this.toolbarDrawerFunc();
    }

    public onRouterOutletActivate($event: Component): void {
        this.routerOutletComponent = $event;
        this.validateToolbar();
    }

    private validateToolbar(): void {
        this.hasToolbar = !isNullOrUndefined((this.routerOutletComponent as IWithToolBarComponent)?.hasToolbar);
    }
}
