import { Directive, OnDestroy } from '@angular/core';
import { IAutocompleteItem } from '@koddington/ga-common';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WlBattlePassMarketsScrollServiceAdapter } from '../services/wrapper-service/wl-battle-pass-markets.scroll-service-adapter';
import { ScrollStrategy } from '../../../common/abstracts/abstract-scroll-strategy';
import { WdsBetMarket, WdsMarketFilterForm } from '../../autogen/BattlePass';
@Directive()
export class WlMarketsTypeStrategy extends ScrollStrategy<WdsBetMarket, WdsMarketFilterForm> implements OnDestroy {
    constructor(private readonly _battlePassService: WlBattlePassMarketsScrollServiceAdapter) {
        super(_battlePassService);
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    public convert(model: WdsBetMarket): IAutocompleteItem<WdsBetMarket> {
        return this.convert_(() => {
            let title = `[${model.id.toString()}] ${model.name}`;
            if (model.isDeleted) {
                title += '(Удалено)';
            }

            return {
                entity: model,
                title: title,
                key: model.id?.toString(),
            };
        });
    }

    protected load(item: WdsMarketFilterForm, fnRules: (form: WdsMarketFilterForm) => boolean = null): Observable<any> {
        return this._battlePassService.search(item)
            .pipe(
                untilDestroyed(this),
                map((result) => result.result)
            );
    }
}
