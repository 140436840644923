import { Injectable } from '@angular/core';
import { DropdownItem, EnumExtensions, IDropdownStrategy } from '@koddington/ga-common';
import { Observable, of } from 'rxjs';
import { WlCompleteTaskTypes, WlCompleteTaskTypesExtensions } from '../../autogen/Shared';

@Injectable({
    providedIn: 'root'
})
export class WlBattlePassCompletedTaskTypesStrategy implements IDropdownStrategy<WlCompleteTaskTypes> {
    getEntities(): Observable<Array<WlCompleteTaskTypes>> {
        const entities = EnumExtensions.toArray(WlCompleteTaskTypes);
        return of(entities);
    }

    map: (model: WlCompleteTaskTypes) => DropdownItem<WlCompleteTaskTypes> = model => {
        return {
            entity: model,
            title: WlCompleteTaskTypesExtensions.format(model),
            id: Number(model)
        };
    }
}
